import { jsx as _jsx } from "react/jsx-runtime";
import { ReactComponent as VisaIcon } from '@icons/payment-systems/visa.svg';
import { ReactComponent as PayPalIcon } from '@icons/payment-systems/paypal.svg';
import { ReactComponent as MasterCardIcon } from '@icons/payment-systems/mastercard.svg';
import { ReactComponent as MaestroIcon } from '@icons/payment-systems/maestro.svg';
import { ReactComponent as ApplePayIcon } from '@icons/payment-systems/apple-pay.svg';
import PaymentSystem from '@entities/payment-system';
import Icon from '@shared/ui/icons/Icon';
const getPaymentSystemsForSelect = (t) => ({
    [PaymentSystem.Visa]: {
        name: t('paymentSystems.visa', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: VisaIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: VisaIcon }),
    },
    [PaymentSystem.PayPal]: {
        name: t('paymentSystems.payPal', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: PayPalIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: PayPalIcon }),
    },
    [PaymentSystem.MasterCard]: {
        name: t('paymentSystems.masterCard', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: MasterCardIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: MasterCardIcon }),
    },
    [PaymentSystem.Maestro]: {
        name: t('paymentSystems.maestro', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: MaestroIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: MaestroIcon }),
    },
    [PaymentSystem.ApplePay]: {
        name: t('paymentSystems.applePay', { ns: 'common' }),
        mainIcon: () => _jsx(Icon, { size: 24, IconComponent: ApplePayIcon, keepOriginalColor: true }),
        smallIcon: () => _jsx(Icon, { size: 16, IconComponent: ApplePayIcon }),
    },
});
const PaymentSystemsUtil = {
    getPaymentSystemsForSelect,
};
export default PaymentSystemsUtil;
